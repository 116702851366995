import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, H1, Subtext } from '../general';


const PricingHero = () => {

    const { t, i18n } = useTranslation();
   
        return (
            <div className='relative'>
                <Col className='relative z-10 container mx-auto items-center'>
                    <H1 className="text-white text-center" text={t('pricing.title')} />
                    <Subtext text={t('pricing.subtext')} color="white" />
                </Col>
            </div>
        );

}

export default PricingHero;