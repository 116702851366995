import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '../general';
import Arrow from '../homepage/features/svg/Arrow';
import { enterpriseFeatures, freeFeatures, lightFeatures, premiumFeatures, freeFeaturesNl, lightFeaturesNl, premiumFeaturesNl, enterpriseFeaturesNl } from './components/featureLists';
import PricingCard from './components/PricingCard';

const PricingSection = () => {
    const [activeCard, setActiveCard] = useState(0);
    const { t, i18n } = useTranslation();

    const pricingCards = [
        <PricingCard packageTitle='FREE' color='#F3987B' price='0' features={i18n.language === "en" ? freeFeatures : freeFeaturesNl} subtext=' ' subtitle={t('pricing.freeSubtitle')} />,
        <PricingCard packageTitle='LIGHT' color='#F17F9A' price='99' features={i18n.language === "en" ? lightFeatures : lightFeaturesNl} subtext={t('pricing.lightSubtext')} subtitle={t('pricing.lightSubtitle')} />,
        <PricingCard packageTitle='PREMIUM' color='#E279A9' price='149' features={i18n.language === "en" ? premiumFeatures : premiumFeaturesNl} subtext={t('pricing.premiumSubtext')} subtitle={t('pricing.premiumSubtitle')} />,
        <PricingCard packageTitle='ENTERPRISE' color='#AD77B3' price='0' features={i18n.language === "en" ? enterpriseFeatures : enterpriseFeaturesNl} subtext={t('pricing.enterpriseSubtext')} subtitle={t('pricing.enterpriseSubtitle')} />
    ]


    function arrowFunction(bool) {

        if (bool) {
            if (activeCard === 3) {
                setActiveCard(0)
                return
            }
            setActiveCard(activeCard + 1)
            return

        } else {
            if (activeCard === 0) {
                setActiveCard(3)
                return
            }
            setActiveCard(activeCard - 1)
            return
        }

    }

    return (
        <div>
            <div className='container mx-auto flex-row justify-center gap-5 hidden lg:flex'>
                {pricingCards.map(item => item)}
            </div>
            <div className='lg:hidden'>
                <Row className='justify-evenly '>
                    <div className='w-10' onClick={() => arrowFunction(false)}>
                        <Arrow />
                    </div>
                    {pricingCards[activeCard]}
                    <div className='w-10 rotate-180' onClick={() => arrowFunction(true)}>
                        <Arrow />
                    </div>
                </Row>
            </div>
        </div>

    );

}

export default PricingSection;