import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import Faq from '../components/pricing/faq';
import PricingBg from '../components/pricing/PricingBg';
import PricingHelp from '../components/pricing/PricingHelp';
import PricingHero from '../components/pricing/PricingHero';
import PricingSection from '../components/pricing/PricingSection';
import Spacer from '../components/spacer';

const Pricing = () => {

    const { t, i18n } = useTranslation();

    function setLanguage(bool) {
        console.log(bool)
        if (bool) {
          return i18n.changeLanguage('nl')
        }
        return i18n.changeLanguage('en')
    
      }
    return (
        <main className='relative overflow-x-hidden'>
            <PricingBg  />
            <Navbar switchFunction={(bool)=>{setLanguage(bool)}} />
            <Spacer />
            <Spacer />
            <PricingHero />
            <Spacer />
            <PricingSection />
            <Spacer />
            <PricingHelp />
            <Spacer />
            <Faq />
            <Spacer />
            <Footer />
        </main>
    );

}

export default Pricing;