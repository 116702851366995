import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, H1, LoginButton, Paragraph, Primarybutton, Row } from '../general';

const PricingHelp = () => {
    const { t } = useTranslation();

    return (
        <div className='container mx-auto relative '>
            <div className='pricingHelpBg'>
                <Row className="z-10 py-5 px-5 lg:py-10 lg:px-20 flex-col lg:flex-row gap-7">
                    <Col>
                        <H1 text={t('pricing.questionTitle')}/>
                        <Paragraph text={t('pricing.questionP')} />
                    </Col>
                    <Row className="w-full justify-between lg:justify-end">
                        <LoginButton text={t('pricing.start')} link='https://www.dashboard.relioapp.com/login' />
                        <Primarybutton text={t('pricing.sales')} />
                    </Row>
                </Row>
            </div>
        </div>
    )
}

export default PricingHelp