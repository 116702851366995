import React from 'react';

const CustomIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" className='relative mx-auto paddingIcon' height="32" viewBox="0 0 42.164 36.893">
            <path id="Icon_awesome-sliders-h" data-name="Icon awesome-sliders-h" d="M40.846,31.238H13.176V29.92A1.321,1.321,0,0,0,11.859,28.6H9.223A1.321,1.321,0,0,0,7.906,29.92v1.318H1.318A1.321,1.321,0,0,0,0,32.555v2.635a1.321,1.321,0,0,0,1.318,1.318H7.906v1.318a1.321,1.321,0,0,0,1.318,1.318h2.635a1.321,1.321,0,0,0,1.318-1.318V36.508h27.67a1.321,1.321,0,0,0,1.318-1.318V32.555A1.321,1.321,0,0,0,40.846,31.238Zm0-13.176H34.258V16.744a1.321,1.321,0,0,0-1.318-1.318H30.305a1.321,1.321,0,0,0-1.318,1.318v1.318H1.318A1.321,1.321,0,0,0,0,19.379v2.635a1.321,1.321,0,0,0,1.318,1.318h27.67V24.65a1.321,1.321,0,0,0,1.318,1.318h2.635a1.321,1.321,0,0,0,1.318-1.318V23.332h6.588a1.321,1.321,0,0,0,1.318-1.318V19.379A1.321,1.321,0,0,0,40.846,18.061Zm0-13.176H23.717V3.568A1.321,1.321,0,0,0,22.4,2.25H19.764a1.321,1.321,0,0,0-1.318,1.318V4.885H1.318A1.321,1.321,0,0,0,0,6.2V8.838a1.321,1.321,0,0,0,1.318,1.318H18.447v1.318a1.321,1.321,0,0,0,1.318,1.318H22.4a1.321,1.321,0,0,0,1.318-1.318V10.156H40.846a1.321,1.321,0,0,0,1.318-1.318V6.2A1.321,1.321,0,0,0,40.846,4.885Z" transform="translate(0 -2.25)" fill="#ad77b3" />
        </svg>

    )
}

export default CustomIcon