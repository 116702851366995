import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Subtext } from '../../general';
import CustomIcon from './customicon';


const PricingCard = (props) => {
    const { t } = useTranslation();

    return (
        <Col className='pricingCardPremium mb-10 justify-between' style={{ borderBottom: `3px solid ${props.color}` }}>
            <Col className="gap-5">

                <div className='pricingPackageTitle mx-auto' style={{ color: `${props.color}` }}>{props.packageTitle}</div>
                <Subtext className='text-xs text-gray-400 mx-auto' text={props.subtitle} />

                {props.packageTitle === 'ENTERPRISE' ? <CustomIcon /> : <div className='pricingPackageTitle mx-auto' style={{ color: `${props.color}` }}><span className='text-xs'>€</span>{props.price}<span className='text-xs'>/month</span></div>}
                <a href='https://dashboard.relioapp.com/register'><button className='packageButton mx-auto' style={{ backgroundColor: `${props.color}` }}>{props.packageTitle === 'ENTERPRISE' ? t('pricing.contact') : t('pricing.getStarted')}</button></a>
                <div className='flex flex-col mb-10'>
                    {props.packageTitle === 'ENTERPRISE' && <ul><b>Everything in Premium and</b></ul>}
                    {props.features.map(item =>
                        <ul>{item}</ul>
                    )}
                </div>
            </Col>

            <p className='text-xs text-gray-400 mx-auto w-40'>
                {props.subtext}
            </p>
        </Col>
    );
}

export default PricingCard;