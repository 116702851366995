

export const freeFeatures = [
    '100mb storage',
    '5 articles',
    '5 users',
    '3 tags',
    '1 admin',
    'Branding',
    'Individual chats']

export const freeFeaturesNl = [
    '100mb opslag',
    '5 artikels',
    '5 gebruikers',
    '3 tags',
    '1 admin',
    'Huisstijl',
    'Individuele chats']


export const lightFeatures = [
    '1000mb storage',
    'unlimited articles',
    '50 users',
    '10 tags',
    '3 admins',
    'Branding',
    'Individual and group chats',
    'Personal support',
    'Leave requests']
export const lightFeaturesNl = [
    '1000mb opslag',
    'unlimited artikels',
    '50 gebruikers',
    '10 tags',
    '3 admins',
    'Huisstijl',
    'Individuele en groep chats',
    'Persoonlijke support',
    'Vakantie aanvragen']

export const premiumFeatures = [
    '2500mb storage',
    'unlimited articles',
    '150 users',
    'unlimited tags',
    '5 admins',
    'Branding',
    'Individual and group chats',
    'Personal support',
    'Enable/disable likes',
    'Enable/disable comments',
    'Leave requests']

export const premiumFeaturesNl = [
    '2500mb opslag',
    'unlimited artikels',
    '150 gebruikers',
    'unlimited tags',
    '5 admins',
    'Huisstijl',
    'Individuele en groep chats',
    'Persoonlijke support',
    'Enable/disable likes',
    'Enable/disable comments',
    'Vakantie aanvragen']

export const enterpriseFeatures = [
    'Your own app with logo',
    'min. 5000mb storage',
    'Unlimited admins']

export const enterpriseFeaturesNl = [
    'Jouw eigen app met logo',
    'min. 5000mb opslag',
    'Ongelimiteerd admins']