import React from 'react';

const Arrow = () => {
    return (

        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 47.282 47.282">
                <path id="Icon_awesome-arrow-alt-circle-left" data-name="Icon awesome-arrow-alt-circle-left" d="M24.2,47.845A23.641,23.641,0,1,1,47.845,24.2,23.637,23.637,0,0,1,24.2,47.845ZM35.262,20.009H24.2V13.251a1.145,1.145,0,0,0-1.954-.81l-10.9,10.953a1.133,1.133,0,0,0,0,1.611l10.9,10.953a1.144,1.144,0,0,0,1.954-.81V28.4H35.262a1.147,1.147,0,0,0,1.144-1.144v-6.1A1.147,1.147,0,0,0,35.262,20.009Z" transform="translate(-0.563 -0.563)" />
            </svg>

        </div>

    )
}

export default Arrow