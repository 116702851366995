import React, { Component } from 'react';

class PricingBg extends Component {

    render() {
        return (
            <div className='absolute z-0 bgPricing '>
                <svg width="100%" height="100%" viewBox="0 0 1922.275 963.524">
                    <defs>
                        <linearGradient id="linear-gradientpric" x1="1.106" y1="-0.092" x2="0.13" y2="1.089" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#3376bd" />
                            <stop offset="0.148" stopColor="#3e76bc" />
                            <stop offset="0.455" stopColor="#d977af" />
                            <stop offset="0.545" stopColor="#ec7ba3" />
                            <stop offset="0.636" stopColor="#f98196" />
                            <stop offset="0.727" stopColor="#ff8b88" />
                            <stop offset="0.818" stopColor="#ff977c" />
                            <stop offset="0.909" stopColor="#ffa672" />
                            <stop offset="1" stopColor="#ffb56b" />
                        </linearGradient>
                    </defs>
                    <path id="Path_41" data-name="Path 41" d="M3141,0H5063.275V620.19s-408.5,284.335-1028.621,336.044S3141,767.619,3141,767.619Z" transform="translate(-3141)" fill="url(#linear-gradientpric)" />
                </svg>

            </div>

        );
    }
}

export default PricingBg;