import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, H1, H2, Paragraph, Row } from '../general';
import Arrow from '../homepage/features/svg/Arrow';
import Spacer from '../spacer';


const FaqQuestion = (props) => {
    return (<div className={props.active ? 'faqBlockActive' : 'faqBlock'} onClick={props.onClick}>
        <Col>
            <Row className="justify-between">
                <H2 text={props.question} />
                <div className={props.active ? " transition-all w-16 h-16 lg:h-8 lg:w-8 -rotate-90" : "transition-all w-16 h-16 lg:h-8 lg:w-8"} >
                    <Arrow />
                </div>
            </Row>
            <Paragraph className={props.active ? ' w-full lg:w-7/12 paragraphActive' : 'w-full lg:w-7/12 paragraphDisabled'} text={props.answer} />
        </Col>
    </div>);
}


const Faq = () => {
    const [activeFaq, setActiveFaq] = useState([
        false, false, false, false, false,
    ])
    const { t } = useTranslation();


    const disabledSections = [
        false, false, false, false, false,
    ]

    const questions = [
        <FaqQuestion active={activeFaq[0]} question={t('pricing.questionTitle1')} answer={t('pricing.answer1')}  onClick={() => {
            if (activeFaq[0] === true) return setActiveFaq(disabledSections);
            let updatedList = [...disabledSections];
            updatedList[0] = true;
            setActiveFaq(updatedList)
        }} />,
        <FaqQuestion active={activeFaq[1]} question={t('pricing.questionTitle2')} answer={t('pricing.answer2')}  onClick={() => {
            if (activeFaq[1] === true) return setActiveFaq(disabledSections);
            let updatedList = [...disabledSections];
            updatedList[1] = true;
            setActiveFaq(updatedList)
        }} />,
        <FaqQuestion active={activeFaq[2]} question={t('pricing.questionTitle3')} answer={t('pricing.answer3')}  onClick={() => {
            if (activeFaq[2] === true) return setActiveFaq(disabledSections);
            let updatedList = [...disabledSections];
            updatedList[2] = true;
            setActiveFaq(updatedList)
        }} />,
        <FaqQuestion active={activeFaq[3]} question={t('pricing.questionTitle4')} answer={t('pricing.answer4')}  onClick={() => {
            if (activeFaq[3] === true) return setActiveFaq(disabledSections);
            let updatedList = [...disabledSections];
            updatedList[3] = true;
            setActiveFaq(updatedList)
        }} />,
        <FaqQuestion active={activeFaq[4]} question={t('pricing.questionTitle5')} answer={t('pricing.answer5')}  onClick={() => {
            if (activeFaq[4] === true) return setActiveFaq(disabledSections);
            let updatedList = [...disabledSections];
            updatedList[4] = true;
            setActiveFaq(updatedList)
        }} />

    ]

    return (
        <div className='container mx-auto'>
            <H1 text={t('pricing.faqTitle')} className="text-center" />
            <Spacer />
            <Col className="gap-10">
                {questions.map(item => item)}
            </Col>
        </div>

    )
}

export default Faq;